import { defineComponent, computed, ref, onMounted, watch } from '@vue/composition-api';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { ServerErrors } from '@/util/graphql';
import Label from '@/uikit/Label.vue';
import Tip from '@/uikit/Tip.vue';
import { serverValidationMixin, server, useValidation } from '@/util/validation';
import { useValidateOrganizationMutation, useOrganizationBankQuery, useOrganizationQuery, OrganizationBankDocument, RoleType } from '@/generated-types/graphql.types';
import { withAuthQueryHook } from '@/util/hooks';
import { IBAN } from '@/Finance/utils/validations';
import { useGetLocalizedPath } from '@/util/globalHelpers';
const useOrganization = withAuthQueryHook(useOrganizationQuery);
export default defineComponent({
    components: {
        Label,
        Tip
    },
    mixins: [validationMixin, serverValidationMixin],
    validations: {
        payout: {
            bank_ascription: {
                required,
                bank_ascription: server('bank_ascription')
            },
            iban: {
                required,
                IBAN,
                iban: server('iban')
            }
        }
    },
    setup() {
        const payout = ref({
            bank_ascription: '',
            iban: ''
        });
        const changeMode = ref(false);
        const { errors, validate, serverErrors } = useValidation();
        const { result: organizationResult } = useOrganization({}, { clientId: 'legacy' });
        const { result: organizationBank } = useOrganizationBankQuery();
        const user = computed(() => globalThis.$store.getters['$_app/user']);
        const isUpdateAllowed = computed(() => user.value?.role_type !== RoleType.VenueManager);
        const information = computed(() => organizationBank?.value?.fetch_iban_details);
        const organization = computed(() => organizationResult?.value?.viewerApiKey?.getApiOrganisation);
        const verified = computed(() => information?.value?.is_active && !changeMode.value);
        const { mutate, loading } = useValidateOrganizationMutation();
        const handleSubmit = async (payoutForm) => {
            try {
                const valid = validate(payoutForm);
                if (!valid) {
                    return;
                }
                await mutate(payoutForm.$model, {
                    refetchQueries: [{ query: OrganizationBankDocument }]
                })
                    .then(() => {
                    changeMode.value = false;
                })
                    .catch(({ graphQLErrors }) => {
                    if (graphQLErrors) {
                        const graphQLError = ServerErrors.extractGQLError(graphQLErrors);
                        const errorKey = Object.values(graphQLError)[0].key;
                        const error = `common.errors.${errorKey}`;
                        errors.general = globalThis.$i18n.t(error);
                    }
                });
            }
            catch (error) {
                validate(payoutForm, error);
            }
        };
        const onIbanVerificationCancel = () => {
            changeMode.value = false;
            errors.general = '';
            errors.fields.bank_ascription = '';
            errors.fields.iban = '';
            payout.value.bank_ascription =
                information.value?.bank_ascription ||
                    organization.value?.organisationName;
            payout.value.iban = information.value?.iban;
        };
        watch(() => information.value, () => {
            payout.value.bank_ascription =
                information.value?.bank_ascription || payout.value?.bank_ascription;
            payout.value.iban = information.value?.iban || payout.value?.iban;
        }, { immediate: true });
        watch(() => organization.value, () => {
            if (!payout.value?.bank_ascription) {
                payout.value.bank_ascription = organization.value?.organisationName;
            }
        }, { immediate: true });
        onMounted(() => {
            payout.value.bank_ascription =
                information.value?.bank_ascription ||
                    organization.value?.organisationName;
            payout.value.iban = information.value?.iban;
        });
        return {
            changeMode,
            errors,
            information,
            isUpdateAllowed,
            loading,
            organization,
            payout,
            serverErrors,
            verified,
            handleSubmit,
            onIbanVerificationCancel,
            useGetLocalizedPath
        };
    }
});
