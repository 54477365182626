import { defineComponent } from '@vue/composition-api';
export default defineComponent({
    name: 'UiLabel',
    props: {
        type: {
            type: String,
            default: 'solid'
        },
        text: {
            type: String,
            default: ''
        },
        color: {
            type: String,
            default: ''
        }
    }
});
