import { defineComponent } from '@vue/composition-api';
import DataTable from '@/components/DataTable.vue';
const fields = [
    {
        name: 'created',
        label: 'CREATED',
        sortable: true
    },
    {
        name: 'invoice',
        label: 'INVOICE #',
        sortable: true
    },
    {
        name: 'host',
        label: 'HOST',
        sortable: true
    },
    {
        name: 'invoiced',
        label: 'INVOICED',
        sortable: true
    },
    {
        name: 'paid',
        label: 'PAID',
        sortable: true
    }
];
const css = {
    table: 'table table-hover table-center',
    theadTh: 'header-item',
    thWrapper: 'th-wrapper',
    arrowsWrapper: 'arrows-wrapper',
    arrowUp: 'arrow up',
    arrowDown: 'arrow down',
    footer: 'footer'
};
export default defineComponent({
    components: {
        DataTable
    },
    setup() {
        const data = [];
        return {
            fields,
            data,
            css
        };
    }
});
